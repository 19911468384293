@import "../../assets/fonts/fonts";
.errorScreen {
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../../assets/images/watch-ID-sorry.jpg");
  height: 100vh;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  .textWrapper {
    text-align: center;
    position: absolute;
    margin-top: 56vh;
    color: white;
    left: 0;
    right: 0;
    .bigTitle {
      font-size: 25px;
      line-height: 30px;
      font-family: $rolex;
      letter-spacing: 2.7px;
      margin-bottom: 50px;
    }
    .smallTitle {
      margin: 0 auto;
      width: 90vw;
      font-size: 16px;
      font-family: $helveticaNeue;
      line-height: 27px;
      letter-spacing: 0.2px;
      padding-bottom: 20px;
    }
    .errCode {
      font-size: 8px;
      font-family: $helveticaNeue;
      margin-top: 11vh;
      span {
        display: block;
        padding-bottom: 5px;
      }
    }
  }
}
