@import "../../assets/fonts/fonts";

.accordion {
  margin: auto;
  box-shadow: 0px 4px 8px -5px rgba(50, 60, 76, 0.35);
  background-color: #ffffff;
  border-radius: 22px;
  margin-top: 20px;
  width: 90vw;

  .accordionTitle {
    padding: 13px 0px;
    color: #212121;
    font-family: $helveticaNeue;
    font-size: 16px;
    font-weight: 900;
    line-height: 19px;
    width: 90%;
    display: flex;
    align-items: center;
    .title {
      padding-left: 14px;
      flex: 1;
    }
    .titleRTL {
      padding-right: 14px;
      flex: 1;
    }
  }
  .open {
    border-radius: 22px 22px 0 0;
    img {
      transform: rotate(180deg);
    }
  }

  .accordionItem {
    margin: auto;
    overflow: hidden;
    transition: max-height 0.2s cubic-bezier(1, 0, 1, 0);
    height: auto;
    max-height: 1200px;
    &.collapsed {
      margin-top: 0;
      max-height: 0;
      transition: max-height 0.2s cubic-bezier(0, 1, 0, 1);
    }
    .accordionContent {
      padding: 0 14px 47px;
      font-size: 14px;
      font-weight: 200;
      line-height: 1.6em;
    }
  }
}
