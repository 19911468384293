@import "../../assets/fonts/fonts";
.logoWhite {
  height: 15%;
  position: absolute;
  margin: 30px auto 0px auto;
  left: 0;
  right: 0;
}
.logoCpo {
  height: 60px;
  position: absolute;
  margin: 30px auto 0px auto;
  left: 0;
  right: 0;
}
.productPage {
  background-image: url("../../assets/images/cover-image-1.jpg");
  background-size: 60vh;
  background-repeat: no-repeat;
  background-position: top center;
  .content {
    padding: 10vw 0;
    .card {
      margin: auto;
      width: 90vw;
      margin-top: 40px;
      background-color: white;
      border-radius: 22px;
      box-shadow: 0px 10px 13px -5px rgba(50, 60, 76, 0.35);
      &:first-of-type {
        margin-top: 60vh;
      }
      .cardContent {
        text-align: center;
        padding-top: 60px;
        padding-bottom: 60px;
        .serialNr {
          color: #212121;
          font-family: $rolexLight;
          font-size: 10px;
          font-weight: 400;
          line-height: 12px;
          letter-spacing: 1.4285715px;
          padding-bottom: 10px;
          margin-top: 20px;
        }
        .referenceNr {
          margin-top: 10px;
        }
        .watchName {
          h2 {
            all: unset;
          }
          color: #212121;
          font-family: $rolex;
          font-size: 25px;
          font-weight: 100;
          line-height: 28px;
          letter-spacing: 2.7px;
        }
        .status,
        .statusGuarantee {
          color: #127749;
          font-family: $helveticaNeue;
          font-size: 16px;
          line-height: 22px;
        }
        .status {
          padding-top: 40px;
        }
        .statusGuarantee {
          padding-top: 10px;
        }
      }
      .guarantee {
        padding: 0 20px 25px 20px;
        font-family: $helveticaNeue;
      }
      .warrantyLegalNotice {
        color: #212121;
        font-family: "HelveticaNow";
        font-size: 14px;
        padding: 15px;
        line-height: 22px;
        letter-spacing: 0.2px;
      }
    }
  }
}
