@import "../../assets/fonts/fonts";
.overlay {
  display: none;
}
.overlayShown {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100vh;
  left: 0;
  display: block;
  z-index: 10;
}

.langButtonRTL,
.langButton {
  position: absolute;
  top: 76.5px;
  z-index: 5;
  height: 15px;
}
.langButton {
  left: 5vw;
}
.langButtonRTL {
  right: 5vw;
}
.navigation {
  display: block;
  list-style-type: none;
  width: 90%;
  background-color: white;
  color: #000;
  padding: 0;
  position: fixed;
  margin-top: 0;
  height: 100vh;
  z-index: 100;
  overflow: scroll;
  .closeButton {
    left: 5vw;
    top: 74.5px;
    position: absolute;
    img {
      width: 15px;
      height: 15px;
    }
  }
  .logoNav {
    display: flex;
    margin: 30px auto 0px auto;
  }
  .logoCpo {
    height: 60px;
    display: flex;
    margin: 30px auto 0px auto;
  }

  ul {
    display: inline-grid;
    margin-left: 5vw;
    width: 90%;

    li {
      margin-bottom: 20px;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0.21px;
      font-family: $helveticaNeue;
      color: #212121;
      .selectedLanguage {
        color: #127749;
      }

      &:first-child {
        margin-top: 100px;
      }

      &:first-of-type {
        font-family: $helveticaNeue;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        padding-bottom: 20px;
        margin-top: 3vh;
        margin-right: 20px;
        border-bottom: 0.5px solid #d4d4d4;
        margin-bottom: 10px;
      }
    }
  }
}

#slider {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99;
  width: 100vw;
  height: 100vw;
  transform: translate3d(-100vw, 0, 0);
  transition: transform 1.5s cubic-bezier(0.19, 1, 0.22, 1);
  .navigation {
    left: 0;
  }
}

#slider.slide-in {
  transform: translate3d(0vw, 0, 0);
}

#sliderRTL {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99;
  width: 100vw;
  height: 100vw;
  transform: translate3d(100vw, 0, 0);
  transition: transform 1.5s cubic-bezier(0.19, 1, 0.22, 1);
  .navigation {
    right: 0;
    .closeButton {
      right: 5vw;
    }
    li {
      margin-right: 5vw;
    }
  }
}

#sliderRTL.slide-in {
  transform: translate3d(0vw, 0, 0);
}

h5 {
  all: unset;
}
