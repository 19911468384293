@import "../../assets/fonts/fonts";
.servicesWrapper,
.guarantee {
  .heading,
  .description {
    color: #212121;
    font-family: $helveticaNeue;
    font-size: 16px;
  }
  .heading {
    font-weight: 900;
    line-height: 22px;
    padding-top: 20px;
  }
  .description {
    line-height: 27px;
    letter-spacing: 0.2px;
    font-weight: 400;
  }
}
