.loading {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../../assets/images/watch-ID-access.jpg");
  height: 100vh;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  .loaderWhite {
    top: 34%;
    height: 13%;
    position: absolute;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
