@import "../../assets/fonts/fonts";
.demo {
  line-height: 20px;
  margin: 0 auto;
  background: red;
  color: white;
  font-family: $rolex;
  font-size: 12px;
  position: fixed;
  width: 100%;
  text-align: center;
}
