@import "../../assets/fonts/fonts";
.associateScreen {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../../assets/images/service-card-bg.png");
  height: 100vh;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  .logoWhite {
    height: 13%;
  }
  .textWrapper {
    text-align: center;
    position: absolute;
    color: #fff;
    left: 0;
    right: 0;
    width: 80vw;
    margin: 21vh auto 0;
    .bigTitle {
      font-size: 18px;
      font-family: $rolex;
      letter-spacing: 0.05em;
      margin-bottom: 20px;
    }
    .smallTitle {
      margin: 0 auto;
      font-size: 16px;
      font-family: $helveticaNow;
      margin-bottom: 50px;
      line-height: 1.2;
    }
    .numbers {
      font-size: 30px;
      font-family: $helveticaNow;
      margin: 0 auto 50px;
      letter-spacing: 20px;
      font-weight: bold;
    }
    .warningIcon {
      left: 0;
      right: 0;
    }
  }
}
