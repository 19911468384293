body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f8f8;
}

.rtl {
  direction: rtl;
}

button {
  all: unset;
}

ul {
  all: unset;
}
