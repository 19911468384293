@import "../../assets/fonts/fonts";
.procedureWrapper {
  margin: auto;
  height: 565px;
  margin-top: 40px;
  width: 90vw;
  border-radius: 25px;
  background-image: url("../../assets/images/servicing-procedure.jpg");
  color: white;
  line-height: 30px;
  letter-spacing: 2.16px;
  font-family: $rolex;
  background-size: cover;
  .procedureWrapperHeader {
    font-size: 20px;
    margin-bottom: 20px;
    h2 {
      all: unset;
    }
  }
  .block,
  .blockRTL {
    padding-top: 10vh;

    a {
      border-radius: 22px;
      color: #127749;
      background-color: white;
      border: none;
      font-family: "HelveticaNow";
      font-size: 16px;
      padding: 10.5px 30px;
      text-decoration: none;
      letter-spacing: normal;
    }
  }
  .block {
    padding-left: 4vw;
  }
  .blockRTL {
    padding-right: 5vw;
  }
}
